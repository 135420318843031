<template>
  <v-combobox
    :value="value"
    :outlined="outlined"
    :dense="dense"
    :readonly="readonly"
    :persistent-placeholder="persistentPlaceholder"
    :rules="rules"
    :required="required"
    v-bind="$attrs"
    v-on="$listeners"
    chips
    multiple
    :append-icon="null"
  >
    <template v-slot:selection="{ attrs, item, select, selected, index }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(index)"
      >
        {{ item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'ChipsCombobox',
  props: {
    value: {},
    dense: { Boolean, default: false },
    rules: {},
    outlined: { Boolean, default: true },
    persistentPlaceholder: Boolean,
    required: { Boolean, default: true },
    readonly: Boolean,
  },
  methods: {
    remove(i) {
      this.value.splice(i, 1);
    },
  },
};
</script>
